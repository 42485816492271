<template>
	<v-container fill-height scroll-y fluid>
		<w-layout align-start justify-center>
			<w-flex xs10 sm8 md6 xl4>
				<v-card flat class="noBackground">
					<v-card-title>
						<w-layout align-center justify-center my-3 row>
							<w-flex xs12 sm10 md8 xl6>
								<v-img v-if="logo" :src="logo" contain :max-height="$vuetify.breakpoint.mdAndUp ? '25vh' : '15vh'"></v-img>
							</w-flex>
						</w-layout>
					</v-card-title>
					<v-card-title>
						<w-layout align-center justify-center :display-1="$vuetify.breakpoint.mdAndUp" :title="$vuetify.breakpoint.smAndDown" text-xs-center>
							<span>{{ $t('profile.messages.reset_password_title') }}</span>
						</w-layout>
					</v-card-title>
					<v-card-text>
						<v-form @submit.prevent>
							<w-email-input
								v-model="email"
								autocomplete
								autofocus
								:label="$t('signin.fields.email')"
								:required="false"
								@keypress.enter.prevent="resetPasswordSendMail()"
							></w-email-input>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<w-layout align-center justify-center>
							<w-flex xs10 sm8 md6 xl6>
								<w-layout align-center column justify-center my-3>
									<w-flex shrink>
										<w-btn @click="resetPasswordSendMail()">{{ $t('profile.messages.reset_password') }}</w-btn>
									</w-flex>
									<w-flex class="grey--text mt-2 pointer text-underline" shrink @click="goBack()">{{ $t('actions.go_back') }}</w-flex>
								</w-layout>
							</w-flex>
						</w-layout>
					</v-card-actions>
				</v-card>
			</w-flex>
		</w-layout>
	</v-container>
</template>

<script>
import { mapState } from 'vuex'
import AppModuleGuard from '@/mixins/ModulesGuards/AppModuleGuard'
import Validator from '@/mixins/Validator'

export default {
	name: 'PasswordReset',
	mixins: [AppModuleGuard, Validator],
	data: function () {
		return {
			email: '',
			domains: [],
			show: false
		}
	},
	computed: {
		...mapState({
			logo: state => state.whitelabel.selected.logo
		})
	},
	created: function () {
		this.domains = [window.location.origin]
	},
	mounted: function () {
		window.addEventListener('keydown', this.resetWithEnter)
	},
	destroyed: function () {
		window.removeEventListener('keydown', this.resetWithEnter)
	},
	methods: {
		resetPasswordSendMail: function () {
			const okMessage = this.$t('profile.messages.email_sent', { email: this.email })
			this.appService
				.resetPasswordSendMail(this.email, this.domains)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, okMessage)
					this.goBack()
				})
				.catch(() => {
					//this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
					this.appEventBus.emit(this.appEvents.SNACKBAR_INFO, okMessage)
					this.goBack()
				})
		},
		resetWithEnter: function (e) {
			if (e.key === 'Enter' && this.$route.name === 'password-reset' && this.email) {
				this.resetPasswordSendMail()
			}
		},
		goBack: function () {
			this.appService.goTo('signin')
		}
	}
}
</script>

<style scoped>
.noBackground {
	background-color: transparent !important;
}

button {
	width: 100%;
}

.underline:hover {
	text-decoration: underline;
}

img {
	margin: auto;
	width: 50px;
}
</style>
